<template>
  <!-- Thumbnail removed: :poster="asset.thumbnail" -->
  <!-- <video controls disablePictureInPicture loop controlsList="nodownload" class="object-scale-down w-full h-full">
        <source :src="asset.url" type="video/mp4">
    </video> -->

  <div class="flex w-full h-full">
    <VideoPlayer
      v-if="asset"
      :src="asset.url"
    />
  </div>
</template>
<script>

const VideoPlayer = () => import('@components/utility/VideoPlayer')

export default {

  components: {
    VideoPlayer
  },
  props: ['asset']
}
</script>
